/* eslint-disable no-useless-escape */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import Layout from 'layouts/Default'
import HeaderSection from 'components/Section/headerSection'
import InfoArea from 'components/Approach/InfoArea'
import ServicesSection from 'components/Approach/Services'
import ContactSection from 'sections/Shared/ContactSection'
import media from 'styles/media'

const PageContainer = styled.div`
  & * {
    box-sizing: border-box;
  }
`

const InfoAreas = styled.section`
  width: 100%;
  padding: 0 248px;
  margin-top: 135px;

  ${media.desktop`
    padding: 0 136px;
  `}

  ${media.tablet`
    padding: 0;
    margin-top: 16px;
  `}

  ${media.phone`
    padding: 0;
    margin-top: 60px;
  `}
`

const BreakerImage = styled.img`
  display: none;
  width: 100%;
  margin-top: 30px;

  ${media.phone`
    display: block;
  `}
`

class WhatWeDo extends React.Component {
  state = {
    isContactFormModalOpen: false,
  }

  toggleScrollBody = (isContactFormModalOpen) => {
    this.setState({
      isContactFormModalOpen,
    })
  }

  render() {
    const { ...restProps } = this.props
    const { isContactFormModalOpen } = this.state
    const {
      title,
      tagline,
      info_area,
      mobile_breaker_image,
      services_tagline,
      services_title,
      services_transitioned_title,
      services_left_column,
      services_centre_column,
      services_right_column,
    } = this.props.data.kenticoCloudItemApproach.elements

    return (
      <Layout {...restProps} isContactFormModalOpen={isContactFormModalOpen}>
        <Helmet>
          <link
            rel="canonical"
            href={`${process.env.GATSBY_ROBOTS_SITE_URL}/what-we-do`}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                url: 'https://distologystudios.com/what-we-do',
                name: 'What we do',
                description:
                  'We combine our IAM credentials with proven product implementation and delivery expertise. Whether it’s an ambitious startup or established enterprise platform, we always focus our approach on three main areas: Identity Consultancy, Design &amp; Engineering and Product Management.',
              }),
            }}
          />
        </Helmet>
        <PageContainer>
          <HeaderSection
            header
            headline={title.value}
            tagline={tagline.value}
            taglineOpacity={1}
            maxWidth={'910px'}
            width={'75%'}
          />
          <BreakerImage src={mobile_breaker_image.value[0].url} />
          <InfoAreas>
            {info_area.map((item, i) => {
              return (
                <InfoArea
                  key={i}
                  itemKey={i}
                  title={item.elements.title.value}
                  infoCards={item.elements.info_cards}
                  ctaText={item.elements.cta_text.value}
                  ctaLinkName={item.elements.cta_link_name.value}
                  ctaLinkSlug={item.elements.cta_link_slug.value}
                  largeImage={
                    item.elements.large_image.value[0] &&
                    item.elements.large_image.value[0].url
                  }
                  smallImage={
                    item.elements.small_image.value[0] &&
                    item.elements.small_image.value[0].url
                  }
                />
              )
            })}
          </InfoAreas>
          <ServicesSection
            tagline={services_tagline.value}
            title={services_title.value}
            transitionedTitle={services_transitioned_title.value}
            leftColumn={services_left_column.resolvedHtml}
            centreColumn={services_centre_column.resolvedHtml}
            rightColumn={services_right_column.resolvedHtml}
          />
          <ContactSection toggleScrollBody={this.toggleScrollBody} />
        </PageContainer>
      </Layout>
    )
  }
}

WhatWeDo.propTypes = {
  data: PropTypes.shape({
    kenticoCloudItemApproach: PropTypes.shape({
      elements: PropTypes.shape({
        title: PropTypes.shape({ value: PropTypes.string }),
        tagline: PropTypes.shape({ value: PropTypes.string }),
        info_area: PropTypes.array,
        mobile_breaker_image: PropTypes.shape({ value: PropTypes.array }),
        services_tagline: PropTypes.shape({ value: PropTypes.string }),
        services_title: PropTypes.shape({ value: PropTypes.string }),
        services_transitioned_title: PropTypes.shape({
          value: PropTypes.string,
        }),
        services_left_column: PropTypes.shape({
          resolvedHtml: PropTypes.string,
        }),
        services_centre_column: PropTypes.shape({
          resolvedHtml: PropTypes.string,
        }),
        services_right_column: PropTypes.shape({
          resolvedHtml: PropTypes.string,
        }),
      }),
    }),
  }),
}

export default WhatWeDo

export const query = graphql`
  {
    kenticoCloudItemApproach {
      elements {
        title {
          value
        }
        tagline {
          value
        }
        mobile_breaker_image {
          value {
            url
          }
        }
        info_area {
          elements {
            title {
              value
            }
            info_cards {
              elements {
                title {
                  value
                }
                description {
                  value
                }
              }
            }
            cta_text {
              value
            }
            cta_link_name {
              value
            }
            cta_link_slug {
              value
            }
            large_image {
              value {
                url
              }
            }
            small_image {
              value {
                url
              }
            }
          }
        }
        services_tagline {
          value
        }
        services_title {
          value
        }
        services_transitioned_title {
          value
        }
        services_left_column {
          resolvedHtml
        }
        services_centre_column {
          resolvedHtml
        }
        services_right_column {
          resolvedHtml
        }
      }
    }
  }
`
